import * as React from "react"
import Layout from "../components/layout"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl
} from "react-bootstrap"
import Logo from "../images/logo-grey.svg"
import gifNL from '../images/screen-nl.gif'
import gifFR from '../images/screen-fr.gif'
import { useState, useEffect } from "react"
import {
  FR, NL,
  AUTH_TOKEN_KEY,
  PARAM_CARD_NMBRE,
  numbersRegularExpression,
  URL_IS_AUTH,
  URL_GETONE_BCEDATA_BYNCRDNUMBER, NCRDNUMBER_LENGTH, translate, ALERT_MSG_INFO, KEY_LANGUAGE_USER, KEY_LANGUAGE_BROWSER
} from "../utils"
import axios from "axios"
import ModalAlert from "../components/modalAlert"

import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// importing footer Ahmad
import { useHistory } from "react-router-dom"

import Footer from '../components/footerfeed'

export default function IndexPage() {
  const [cardNmbre, setCardNmbre] = useState('');
  const [showAlert, setshowAlert] = useState(false);
  const [alertTitle, setalertTitle] = useState('');
  const [alertMessage, setalertMessage] = useState('');
  const [placeholder,setplaceholder] = useState('');
  const [gifComputer,setgifComputer] = useState(gifFR);

  const getQuery = () => {
    if (typeof window !== 'undefined') {
      return new URLSearchParams(window.location.search);
    }
    return new URLSearchParams();
  };

  const getQueryStringVal = (key)  => {
    if(getQuery().get(key))  return getQuery().get(key).toUpperCase();
  };

    if(getQueryStringVal("lng")) {
      localStorage.setItem(KEY_LANGUAGE_USER,getQueryStringVal("lng") );
      localStorage.setItem(KEY_LANGUAGE_BROWSER,getQueryStringVal("lng") );
     }

  useEffect(() => {
    auth()
    setplaceholder(translate('wat is uw kaartnummer')+"?")

    var userLanguage = localStorage.getItem(KEY_LANGUAGE_USER)
    var browserLanguage = localStorage.getItem(KEY_LANGUAGE_BROWSER);



     console.log("lng",browserLanguage)

    if (userLanguage) {
      if (userLanguage === FR) {
        setgifComputer(gifFR)
      } else setgifComputer(gifNL)
    }else if (browserLanguage) {
      if (browserLanguage === FR) {
        setgifComputer(gifFR)
      }else setgifComputer(gifNL)
    }
  },[])

  const auth = () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const url = URL_IS_AUTH;
    axios.get(url,data)
      .then(res => {
        if (res.data) {
          window.location.href = '/feed';
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data);
        }
        console.log(err)
      });
  }

  const checkCardNumbre = async (ncrd_num) => {
    if (ncrd_num.length === NCRDNUMBER_LENGTH) {
      const data = {
        ncrd_num,
      };
      axios.post(URL_GETONE_BCEDATA_BYNCRDNUMBER,data)
        .then(res => {
          if (res.data) {
            window.location.href = `/sign-up-three?${PARAM_CARD_NMBRE}=${ncrd_num}`
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data);
          }
          console.log(err)
          window.location.href =  `/oups?${PARAM_CARD_NMBRE}=${ncrd_num}`
        });
    }else {
      setalertMessage(`${translate("card number consists of 11 digits, current card number of digits is")} ${ncrd_num.length}`)
      setshowAlert(true)
      setalertTitle(ALERT_MSG_INFO)
      //alert(`${translate("card number consists of 11 digits, current card number of digits is")} ${ncrd_num.length}`)
    }
  }

  const onChangeCardNmbreInput = (value) => {
    if (value.match(numbersRegularExpression)) setCardNmbre(value)
    if (value === '') setCardNmbre(value)
  }

  return (
    <Layout>
      <div className="welcome">
        <Col className="pt-4 text-right welcomeBtnWrap">
          <a className="btn btn-dark welcomeBtn" href={'/login'}>
            {"_"+translate("aanmelden")}
          </a>
        </Col>
        <Container className="ContainerGap inder-container-yellow">
          <Row className="mt-lg-5 index-yellow-row">
            <Col sm="12">
              <div className="welcome-logo d-block d-lg-none mb-5">
                <img className="img-fluid" src={Logo} alt="Enter Business" />
              </div>
            </Col>
            <Col sm="12" md="12" lg="6" className="order-lg-2 mb-5 mt-lg-0 ">
              <div classNam="welcomeFeatured">
                <div>
                  <div className="imgCaption captionOne  mb-3">
                    {/*<h2 className="h1-title text-primary">
                      Des dossiers business complets à votre disposition
                    </h2>*/}
                  </div>
                  <div className="imgboxWrap gif-home">
                    <img
                      className="img-fluid yellow-gif"
                      alt=""
                      src={gifComputer}
                    />
                    <div className="imgCaption captionTwo">
                      {/*<h2 className="h1-title text-danger">
                        Des avantages exclusifs de nos partenaires!
                      </h2>*/}
                    </div>
                  </div>
                  <div className="imgCaption mt-md-5 imgCaptionLast">
                    {/*<h2 className="h1-title text-success">
                      Les actus quotidiennes de votre secteur
                    </h2>*/}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm="12" md="12" lg="6" className="pr-xl-5">
              <Row>
                <Col sm="12" md="6" lg="12" xl="12">
                  <div className="welcome-logo d-none d-lg-block mb-5">
                    <img
                      className="img-fluid"
                      src={Logo}
                      alt="enter-business"
                    />
                  </div>
                  <h1 className="h0-title">
                    {translate("welkom in de wereld van de Goede Deals")+"!"}
                  </h1>
                  <p className="mt-4 index-paragraph-yellow">
                    {translate('concentreer u op uw bedrijf en ontvang tips, kortingen en voordelen om u te helpen slagen')+". "}</p>
                    
                  <p className="mt-4 index-paragraph-yellow">
                    {translate("Enter-business is een echte")+". "}
                  </p>
                  <p className="mt-4 index-paragraph-yellow"><strong>
                    {translate("schrijf u in! Het is gratis en zonder voorwaarden")+". "}</strong>
                  </p>
                </Col>
                <Col sm="12" md="6" lg="12" xl="12">
                  <Form onSubmit={(e) => e.preventDefault() }>
                    <p className="mt-4 mb-4 text-secondary mb-0 font-weight-bold index-yellow-subtitle">
                      {translate("ik heb mijn enter-business kaart ontvangen en wil die gratis activeren")+"."}
                    </p>
                    <InputGroup className="mb-3">
                      <FormControl
                        value={cardNmbre}
                        onChange={(e) => onChangeCardNmbreInput(e.target.value)}
                        placeholder={placeholder}
                        aria-label=""
                        aria-describedby="basic-addon2"
                      />
                      <InputGroup.Append>
                        <InputGroup id="basic-addon2">
                          {/* <Link  to={(cardNmbre !== '')?`/sign-up?${PARAM_CARD_NMBRE}=${cardNmbre}`:null}>
                            <Button
                              onClick={() => {if (cardNmbre === '') {alert('Please encode your card number.')}}}
                              style={{height: "100%"}}
                              className="btn-success-gradient h3-title"
                              variant="text-uppercase"
                            >
                              Go
                            </Button>
                          </Link> */}
                            <Button
                              onClick={() => checkCardNumbre(cardNmbre)}
                              style={{height: "100%"}}
                              className="btn-success-gradient h3-title"
                              variant="text-uppercase"
                            >
                              Go
                            </Button>
                        </InputGroup>
                      </InputGroup.Append>
                    </InputGroup>
                    <p className="mt-4 mb-4 text-secondary font-weight-bold index-yellow-subtitle">
                      {translate("ik heb nog geen kaart ontvangen en wens mij gratis in te schrijven")+"."}
                    </p>

                    <div className="d-block index-activation-btn-yellow">
                    <a href={`/sign-up-three?${PARAM_CARD_NMBRE}=${false}`}><div
                      className="bnt btn-primary-gradient index-btn-yellow"
                    >
                      {translate('activeer mijn verzoek')}
                    </div></a>
                  </div>
                  </Form>
                </Col>
                {/* adding footer */}
                < Footer />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      {(showAlert)?
        <ModalAlert
          alertTitle={alertTitle}
          alertMessage={alertMessage}
          closeModal={() => setshowAlert(false)}
        />:null
      }
    </Layout>
  )
}

